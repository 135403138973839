var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-ryProbe-info" }, [
    _c("div", { staticClass: "info-header" }, [
      _vm.info.repetition
        ? _c("div", { staticClass: "info-warn" }, [
            _c("span", [_vm._v("ID可能存在重复烧录的情况")]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm._f("getYMD")(_vm.info.updateAt)))])
          ])
        : _vm._e(),
      !_vm.info.repetition
        ? _c("div", { staticClass: "info-warn" }, [
            _c("span", [_vm._v("质检时间")]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm._f("getYMD")(_vm.info.updateAt)))])
          ])
        : _vm._e(),
      _c("div", { staticClass: "info-id" }, [
        _vm._v(_vm._s(_vm.info._probeId))
      ]),
      _c(
        "div",
        { class: { "info-status": true, pass: _vm.info.passed } },
        [
          !_vm.info.passed
            ? _c("span", [_vm._v("质检未通过")])
            : _c("span", [_vm._v("质检通过")]),
          _c(
            "Button",
            { attrs: { type: "danger" }, on: { click: _vm.resetCheck } },
            [_vm._v("重新检测")]
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "info-body" }, [
      _c(
        "div",
        { staticClass: "info-content" },
        [
          _c("div", { staticClass: "info-content-title" }, [
            _vm._v("出厂质检项：")
          ]),
          _vm._l(_vm.failArr, function(item) {
            return _c(
              "div",
              {
                key: item.data,
                class: { "info-content-item": true, pass: item.state }
              },
              [
                !item.state
                  ? _c("Icon", { attrs: { name: "clear" } })
                  : _c("Icon", { attrs: { name: "checked" } }),
                _vm._v(" " + _vm._s(item.msg) + " "),
                item.data ? _c("span", [_vm._v(_vm._s(item.data))]) : _vm._e()
              ],
              1
            )
          }),
          _vm._l(_vm.passArr, function(item) {
            return _c(
              "div",
              {
                key: item.data,
                class: { "info-content-item": true, pass: item.state }
              },
              [
                !item.state
                  ? _c("Icon", { attrs: { name: "clear" } })
                  : _c("Icon", { attrs: { name: "checked" } }),
                _vm._v(" " + _vm._s(item.msg) + " "),
                item.data ? _c("span", [_vm._v(_vm._s(item.data))]) : _vm._e()
              ],
              1
            )
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }