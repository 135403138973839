require("core-js/modules/es.array.filter");

require("core-js/modules/es.array.slice");

require("core-js/modules/es.function.name");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

require("core-js/modules/web.dom-collections.iterator");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "vant/es/dialog/style", "vant/es/dialog", "vant/es/toast/style", "vant/es/toast", "vant/es/icon/style", "vant/es/icon", "vant/es/button/style", "vant/es/button", "@/utils/time"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("vant/es/dialog/style"), require("vant/es/dialog"), require("vant/es/toast/style"), require("vant/es/toast"), require("vant/es/icon/style"), require("vant/es/icon"), require("vant/es/button/style"), require("vant/es/button"), require("@/utils/time"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.style, global.dialog, global.style, global.toast, global.style, global.icon, global.style, global.button, global.time);
    global.info = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _style, _dialog, _style2, _toast, _style3, _icon, _style4, _button, _time) {
  "use strict";

  var _interopRequireDefault = require("/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/interopRequireDefault");

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _dialog = _interopRequireDefault(_dialog);
  _toast = _interopRequireDefault(_toast);
  _icon = _interopRequireDefault(_icon);
  _button = _interopRequireDefault(_button);
  var _default = {
    name: "RyProbeInfo",
    components: {
      Button: _button.default,
      Icon: _icon.default
    },
    filters: {
      getYMD: _time.getYMD
    },
    data: function data() {
      var ossHost = this.$config.base.ossHost;
      return {
        ossHost: ossHost,
        name: "",
        info: {},
        passArr: [],
        failArr: []
      };
    },
    created: function created() {
      var vm = this;
      var USER_NAME = vm.$config.keys.USER_NAME;
      var name = vm.$localStorage.getItem(USER_NAME);
      vm.name = name;

      if (!name) {
        vm.$router.replace({
          path: "/factory/login"
        });
        return;
      }

      vm.loadInfo();
      vm.tt = _toast.default.loading({
        message: "加载中...",
        forbidClick: true,
        overlay: true,
        duration: 0
      });
    },
    methods: {
      loadInfo: function loadInfo() {
        var vm = this;
        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "probe"),
          params: {
            probeId: vm.$route.query.uuid
          },
          except: true
        }).then(function (res) {
          if (!res.data) {
            (0, _toast.default)("网络错误");
            return;
          }

          res.data._probeId = vm.fomatId(res.data.probeId);
          vm.info = res.data;
          vm.passArr = res.data.content.filter(function (item) {
            return item.state;
          });
          vm.failArr = res.data.content.filter(function (item) {
            return !item.state;
          });
          vm.tt.clear();
        });
      },
      fomatId: function fomatId(probeId) {
        var _probeId = '';
        _probeId += probeId.slice(0, 4);
        _probeId += '    ';
        _probeId += probeId.slice(4, 8);
        _probeId += '    ';
        _probeId += probeId.slice(8, 12);
        _probeId += '    ';
        _probeId += probeId.slice(12, 16);
        return _probeId;
      },
      resetCheck: function resetCheck() {
        var vm = this;

        _dialog.default.confirm({
          title: "提示",
          message: "点击”确定“后会将当前检测结果清空，以便重新检测",
          confirmButtonColor: "#256FD9"
        }).then(function () {
          var USER_NAME = vm.$config.keys.USER_NAME;
          var name = vm.$localStorage.getItem(USER_NAME);
          vm.tt = _toast.default.loading({
            message: "加载中...",
            forbidClick: true,
            overlay: true,
            duration: 0
          });
          vm.$http({
            method: "put",
            url: "".concat(vm.$config.base.PROBE_BASE_URL, "probe"),
            data: {
              name: name,
              probeId: vm.$route.query.uuid,
              password: vm.$config.base.password
            },
            except: true
          }).then(function (res) {
            if (res.code != 1) {
              (0, _toast.default)("网络错误");
              return;
            }

            vm.tt.clear();

            _toast.default.success({
              message: "该条记录已删除，可重新对该设备进行出厂检测~",
              forbidClick: true,
              overlay: true,
              duration: 3000,
              onClose: function onClose() {
                vm.$router.replace({
                  path: "/ryProbe/list"
                });
              }
            });
          });
        }).catch(function () {// on cancel
        });
      }
    }
  };
  _exports.default = _default;
});